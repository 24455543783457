import { Add } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    FormControl,
    InputLabel,
    NativeSelect,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Download } from "react-feather";
import { useNavigate } from "react-router";
import ExtraChip from "../../../../components.js/ExtraChip";
import { downloadTXT, downloadPDF, updateElement } from "../../../../database/API";
import CanviHabitacio from "../TableReserves/CanviHabitacio";
import Conceptes from "./Conceptes";
import CrearFactura from "./CrearFactura";
import Dada from "./Dada";
import Descompte from "./Descompte";
import ReenviarCorreus from "./ReenviarCorreus";
import { pagaments } from "../../../../utils/Utils";

function Informacio({ reserva, setUp, downloadFactura, loading, setLoading, preuFinal, setDownloadLoading }) {
    const classes = useStyles();
    const [openHab, setOpenHab] = useState(false);
    const [openCrear, setOpenCrear] = useState(false);
    const [pagament, setPagament] = useState(reserva?.pagament);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const exportar = () => {
        enqueueSnackbar("S'ha copiat el còdi", {
            variant: "success",
        });
    };

    const download = async () => {
        await downloadTXT(reserva.id, "C");
        setUp((prev) => prev + 1);
    };

    const download2 = async () => {
        await downloadTXT(reserva.id, "R");
        setUp((prev) => prev + 1);
    };

    const downloadP = async () => {
        await downloadPDF(reserva.id);
        setUp((prev) => prev + 1);
    };

    const changePagament = async (p) => {
        try {
            setPagament(p);
            await updateElement("changePagament", reserva.unique_id, { pagament: p });
            setUp((prev) => prev + 1);
            enqueueSnackbar("Pagament canviat", {
                variant: "success",
            });
        } catch (error) {
            enqueueSnackbar("Alguna cosa no ha anat bé", {
                variant: "error",
            });
        }
    };

    return (
        <Box className={classes.block}>
            <Typography variant="h2">Informació</Typography>
            <Box m={3}></Box>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableBody>
                        <Dada
                            title="Habitació"
                            value={
                                <Tooltip title="Canviar habitació">
                                    <Button onClick={() => setOpenHab(true)}>{reserva?.habitacio.nom}</Button>
                                </Tooltip>
                            }
                        />
                        <Dada title="Booking" value={reserva?.booking === 1 ? "Reserva Booking" : "-"} />
                        <Dada
                            title="Codi d'entrada"
                            button={
                                <CopyToClipboard text={reserva?.codi} onCopy={exportar}>
                                    <Tooltip title="Clica per a copiar el còdi">
                                        <Button>{reserva?.codi}</Button>
                                    </Tooltip>
                                </CopyToClipboard>
                            }
                        />

                        <Descompte reserva={reserva} setUp={setUp} />
                        <Dada title="Preu final" value={preuFinal + " €"} />
                        <Dada title="Nom contacte" value={reserva?.nom_contacte} />
                        <Dada title="E-mail contacte" value={<ReenviarCorreus email={reserva?.email_contacte} setUp={setUp} />} />

                        <Dada title="Telèfon contacte" value={reserva?.phone_contacte} />

                        {reserva?.lang && <Dada title="Idioma" value={reserva?.lang} />}

                        {reserva?.codi_xec && (
                            <>
                                <TableRow>
                                    <TableCell align="left">Xec Regal</TableCell>
                                    <TableCell
                                        align="right"
                                        style={{
                                            fontWeight: 600,
                                        }}
                                    >
                                        <Box my={2}>
                                            <Chip
                                                onClick={() => navigate(`/admin/codis/${reserva?.codi_xec?.unique_id}`)}
                                                label={reserva?.codi_xec?.xec?.nom}
                                                avatar={<Avatar>{reserva?.codi_xec?.xec.nits}</Avatar>}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">Descarrega el Xec Regal</TableCell>
                                    <TableCell
                                        align="right"
                                        style={{
                                            fontWeight: 600,
                                        }}
                                    >
                                        <Tooltip title="Descarrega el Xec Regal">
                                            <Button
                                                LinkComponent={"a"}
                                                target="_blank"
                                                download
                                                href={`https://api.lacabanaberga.cat/storage/${reserva?.codi_xec?.pdf}`}
                                            >
                                                <Download />
                                            </Button>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                        <Dada
                            title="Descarrega TXT Reserva"
                            button={
                                <Tooltip title="Descarrega TXT Reserva">
                                    <Button onClick={download2}>
                                        <Download />
                                    </Button>
                                </Tooltip>
                            }
                        />
                        <Dada
                            title="Descarrega TXT Contracte"
                            button={
                                <Tooltip title="Descarrega TXT Contracte">
                                    <Button onClick={download}>
                                        <Download />
                                    </Button>
                                </Tooltip>
                            }
                        />
                        <Dada
                            title="Descarrega PDF"
                            button={
                                <Tooltip title="Descarrega PDF mossos (fitxa viatger)">
                                    <Button onClick={downloadP}>
                                        <Download />
                                    </Button>
                                </Tooltip>
                            }
                        />
                        {reserva?.rao ? (
                            <>
                                <Dada
                                    title="Factura"
                                    button={
                                        <Tooltip title="Descarregar factura">
                                            <Button onClick={downloadFactura}>{loading ? <CircularProgress size={21} /> : <Download />}</Button>
                                        </Tooltip>
                                    }
                                />
                                <Dada
                                    title="Conceptes afegits"
                                    button={
                                        <Conceptes
                                            reserva={reserva}
                                            enqueueSnackbar={enqueueSnackbar}
                                            setUp={setUp}
                                            setDownloadLoading={setDownloadLoading}
                                            loading={loading}
                                        />
                                    }
                                />
                            </>
                        ) : (
                            <Dada
                                title="Factura"
                                button={
                                    <Tooltip title="Crear factura">
                                        <Button onClick={() => setOpenCrear(true)}>
                                            <Add />
                                        </Button>
                                    </Tooltip>
                                }
                            />
                        )}
                        {reserva?.extra && (
                            <TableRow>
                                <TableCell align="left">Extres</TableCell>
                                <TableCell
                                    align="right"
                                    style={{
                                        fontWeight: 600,
                                    }}
                                >
                                    {JSON.parse(reserva?.extra)?.map((item) => item?.checked && <ExtraChip item={item} />)}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box p={2} width={"100%"}>
                <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Pagament
                    </InputLabel>
                    <NativeSelect
                        defaultValue={30}
                        fullWidth
                        onChange={(val) => changePagament(val.target.value)}
                        style={{ width: "100%" }}
                        value={pagament}
                        inputProps={{
                            name: "pagament",
                            id: "uncontrolled-native",
                        }}
                    >
                        <option value={""}></option>
                        {pagaments?.map((p) => (
                            <option value={p.id}>{p.nom}</option>
                        ))}
                    </NativeSelect>
                </FormControl>
            </Box>
            <CanviHabitacio open={openHab} setOpen={setOpenHab} habitacio={reserva?.habitacio.id} reserva_id={reserva?.id} setUp={setUp} />
            <CrearFactura setOpen={setOpenCrear} enqueueSnackbar={enqueueSnackbar} open={openCrear} setLoading={setLoading} setUp={setUp} />
        </Box>
    );
}

export default Informacio;

const useStyles = makeStyles((theme) => ({
    block: {
        boxShadow: "#00000020 1px 3px 20px 1px ",
        borderRadius: 30,
        padding: 30,
        zIndex: 0,
        marginBottom: 40,
        backgroundColor: "white",
        overflow: "hidden",
    },
    dates: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));
