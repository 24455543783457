import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import React from "react";
import img from "../assets/img/explicacio.webp";

export const DialogExplicacio = ({ open, setOpen }) => {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth={"lg"}>
            <DialogContent>
                <img alt="Explicació" src={img} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>D'acord</Button>
            </DialogActions>
        </Dialog>
    );
};
