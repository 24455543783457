import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useEffect, useState } from "react";
import { updatePersona } from "../database/API";
import { Add } from "@mui/icons-material";
import Styles from "../views/public/Style";
import Thumb from "./Thumb";
import { relacio } from "../utils/Utils";

export default function PersonaEdit({ open, setOpen, persona, paisos, setUp, municipis, provincies }) {
    const classes = Styles();
    const [menor, setMenor] = useState();

    useEffect(() => {
        setMenorData(persona?.naixement);
        reset(persona);
        setValue("nacionalitat", persona?.nacionalitat);
    }, [persona]);

    const guardar = (values) => {
        console.log(values);

        const get = async () => {
            const message = await updatePersona(values, persona.id);
            setOpen(false);
            setUp((prev) => prev + 1);
            console.log(message);
        };
        get();
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        trigger,
        watch,
    } = useForm({
        defaultValues: persona,
    });

    useEffect(() => {
        const { unsubscribe } = watch((value, { name }) => {
            if (name === "naixement") {
                console.log(value.naixement);
                setMenorData(value.naixement);
            }
        });
        return () => unsubscribe();
    }, [watch]);

    const setMenorData = (data) => {
        var ageDifMs = Date.now() - Date.parse(data);
        var ageDate = new Date(ageDifMs);
        var years = Math.abs(ageDate.getUTCFullYear() - 1970);
        if (years <= 14) {
            setMenor(true);
        } else {
            setMenor(false);
        }
    };

    return (
        <Dialog open={open} maxWidth={"lg"} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <form onSubmit={handleSubmit(guardar)}>
                <DialogTitle id="alert-dialog-title">Editar Persona</DialogTitle>
                <DialogContent>
                    <Box my={5}>
                        <Grid container spacing={3}>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    error={Boolean(errors && errors?.nom)}
                                    helperText={errors && errors?.persones?.nom?.message}
                                    fullWidth
                                    label={"Nom"}
                                    name={"nom"}
                                    type="text"
                                    variant="outlined"
                                    {...register("nom")}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    error={Boolean(errors && errors?.cognom1)}
                                    helperText={errors && errors?.cognom1?.message}
                                    fullWidth
                                    label={"Primer cognom"}
                                    type="text"
                                    variant="outlined"
                                    name={"cognom1"}
                                    {...register("cognom1")}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    error={Boolean(errors && errors?.cognom2)}
                                    helperText={errors && errors?.cognom2?.message}
                                    fullWidth
                                    label={"Segon cognom"}
                                    type="text"
                                    variant="outlined"
                                    name={"cognom2"}
                                    {...register("cognom2")}
                                />
                            </Grid>
                        </Grid>

                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) => {}}
                                    name={'menor'}
                                    {...register('menor')}
                                />
                            }
                            style={{
                                paddingTop: 20,
                            }}
                            label={
                                <Box lineHeight={'9px'}>
                                    <Typography>Menor de 14 anys</Typography>
                                    <Typography variant="caption">
                                        (Sense document d'identificació)
                                    </Typography>
                                </Box>
                            }
                        /> */}

                        <Box my={4} />
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="relacio">Parentesc amb la persona 1 (Contacte)</InputLabel>
                                    <Select native label={"Parentesc amb la persona 1 (Contacte)"} {...register(`relacio`)}>
                                        <option value={""}></option>
                                        {relacio?.map((rel) => (
                                            <option value={rel.id}>{rel.nom}</option>
                                        ))}
                                    </Select>
                                </FormControl>
                                {/* <TextField
                                    error={Boolean(errors && errors?.phone)}
                                    helperText={errors && errors?.phone?.message}
                                    fullWidth
                                    label={"Telèfon"}
                                    type="text"
                                    variant="outlined"
                                    name={"phone"}
                                    {...register("phone")}
                                /> */}
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    error={Boolean(errors && errors?.naixement)}
                                    helperText={errors && errors?.naixement?.message}
                                    fullWidth
                                    label={"Data naixement"}
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name={"naixement"}
                                    {...register("naixement")}
                                />
                            </Grid>
                        </Grid>

                        <Box my={4} />
                        <Collapse in={!menor}>
                            <Box>
                                <Grid container spacing={3}>
                                    <Grid item md={4} mt={1}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="tipus">{"País"}</InputLabel>
                                            <Select native label={"País"} {...register("nacionalitat")}>
                                                <option value={""}></option>
                                                {paisos.map((pais) => (
                                                    <option value={pais?.id}>{pais?.nom}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={4} mt={1}>
                                        <Collapse in={watch("nacionalitat") === "ESP"}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="tipus">{"Provincia"}</InputLabel>
                                                <Select native label={"Provincia"} {...register("provincia")}>
                                                    <option value={""}></option>
                                                    {provincies.map((provincia) => (
                                                        <option value={provincia?.id}>{provincia?.nom}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Collapse>
                                    </Grid>

                                    <Grid item md={4} mt={1}>
                                        <Collapse in={watch("nacionalitat") === "ESP"}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="tipus">{"Municipi"}</InputLabel>
                                                <Select native label={"Municipi"} {...register("municipi")}>
                                                    <option value={""}></option>
                                                    {municipis
                                                        .filter((m) => m.provincia_id === watch("provincia"))
                                                        .map((municipi) => (
                                                            <option value={municipi?.id}>{municipi?.nom}</option>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Collapse>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} mt={2}>
                                    <Grid item md={4} xs={12}>
                                        <TextField
                                            error={Boolean(errors && errors?.codi_postal)}
                                            helperText={errors && errors?.codi_postal?.message}
                                            fullWidth
                                            label={"Còdi postal"}
                                            type="text"
                                            variant="outlined"
                                            name={"codi_postal"}
                                            {...register("codi_postal")}
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <TextField
                                            error={Boolean(errors && errors?.poblacio)}
                                            helperText={errors && errors?.poblacio?.message}
                                            fullWidth
                                            label={"Població"}
                                            type="text"
                                            variant="outlined"
                                            name={"poblacio"}
                                            {...register("poblacio")}
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <TextField
                                            error={Boolean(errors && errors?.direccio)}
                                            helperText={errors && errors?.direccio?.message}
                                            fullWidth
                                            label="Direcció"
                                            type="direccio"
                                            variant="outlined"
                                            name={"direccio"}
                                            {...register("direccio")}
                                        />
                                    </Grid>
                                </Grid>
                                <Box mt={3} mb={2}>
                                    <Typography variant="h3">Dades del document d'identitat</Typography>
                                </Box>
                                <Grid container spacing={3}>
                                    <Grid item md={3} xs={12}>
                                        <Box mt={2}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="tipus">{"Tipus de document"}</InputLabel>
                                                <Select native label={"Tipus de document"} name={"tipus"} {...register("tipus")}>
                                                    <option value={"D"}>DNI</option>
                                                    <option value={"P"}>Passaport</option>
                                                    <option value={"C"}>Carnet de conduir</option>
                                                    <option value={"I"}>Carta d'identitat</option>
                                                    <option value={"N"}>Permís de residència</option>
                                                    <option value={"X"}>Permís de residència fora UE</option>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <TextField
                                            error={Boolean(errors && errors?.dni)}
                                            helperText={errors && errors?.dni?.message}
                                            fullWidth
                                            label={"Número de document"}
                                            margin="normal"
                                            variant="outlined"
                                            name={"dni"}
                                            {...register("dni")}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <TextField
                                            error={Boolean(errors && errors?.caducitat)}
                                            helperText={errors && errors?.caducitat?.message}
                                            fullWidth
                                            label={"Data d'expedició"}
                                            margin="normal"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputProps: {
                                                    max: moment().format("YYYY-MM-DD"),
                                                },
                                            }}
                                            name={"caducitat"}
                                            {...register("caducitat")}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <Box mt={2}>
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="genere">Gènere</InputLabel>
                                                <Select native label={"Gènere"} name={"genere"} {...register("genere")}>
                                                    <option value={"M"}>M</option>
                                                    <option value={"F"}>F</option>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box my={2} />
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            error={Boolean(errors && errors?.suport)}
                                            helperText={errors && errors?.suport?.message}
                                            fullWidth
                                            label={"Número de suport del DNI"}
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name={"suport"}
                                            {...register("suport")}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            error={Boolean(errors && errors?.email)}
                                            helperText={errors && errors?.email?.message}
                                            fullWidth
                                            label="E-mail"
                                            margin="normal"
                                            type="email"
                                            variant="outlined"
                                            name={"email"}
                                            {...register("email")}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Collapse>
                        <Grid container spacing={3}>
                            {/* <Grid item md={6} xs={12}>
                                <Box mt={3}>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Box>
                                            <Typography variant={'body1'}>
                                                Fotografia document (davant)
                                            </Typography>
                                            <label htmlFor={'dniDavant'}>
                                                <input
                                                    id={'dniDavant'}
                                                    name={'dniDavant'}
                                                    type="file"
                                                    {...register('dniDavant', {
                                                        onChange: () => {
                                                            trigger(
                                                                'dniDavant'
                                                            );
                                                        },
                                                    })}
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                />
                                                <Box className={classes.boto}>
                                                    <Add />
                                                    <Typography
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    >
                                                        Afegir imatge
                                                    </Typography>
                                                </Box>
                                            </label>
                                        </Box>
                                        <Box p={2}>
                                            <Thumb
                                                file={
                                                    getValues('dniDavant')?.[0]
                                                }
                                            />
                                        </Box>
                                    </Box>
                                    <Typography variant="error">
                                        {errors?.dniDavant?.message}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <Box mt={3}>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Box>
                                            <Typography variant={'body1'}>
                                                Fotografia document (darrere)
                                            </Typography>
                                            <label htmlFor={'dniDarrere'}>
                                                <input
                                                    id={'dniDarrere'}
                                                    name={'dniDarrere'}
                                                    type="file"
                                                    {...register('dniDarrere', {
                                                        onChange: () => {
                                                            trigger(
                                                                'dniDarrere'
                                                            );
                                                        },
                                                    })}
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                />
                                                <Box className={classes.boto}>
                                                    <Add />
                                                    <Typography
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    >
                                                        Afegir imatge
                                                    </Typography>
                                                </Box>
                                            </label>
                                        </Box>
                                        <Box p={2}>
                                            <Thumb
                                                file={
                                                    getValues('dniDarrere')?.[0]
                                                }
                                            />
                                        </Box>
                                    </Box>

                                    <Typography variant="error">
                                        {errors?.dniDarrere?.message}
                                    </Typography>
                                </Box>
                            </Grid> */}
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Tancar</Button>
                    <Button autoFocus type="submit" variant="contained">
                        Guardar
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
