import { Box, CircularProgress, Container, Fade, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { ca, es, enGB } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ReactDatePicker from "react-datepicker";
import "../../theme/style.css";
import { addDays } from "date-fns";
import { calendaris, calendarisBooking, createReservaSenseCheckin, ferReserva, get } from "../../database/API";
import "moment/locale/ca";
import "moment/locale/es";
import { WheelchairPickupRounded } from "@mui/icons-material";
import CustomButton from "../../components.js/CustomButton";
import clsx from "clsx";
import FormulariReserva from "../../components.js/FormulariReserva";
import { animated, useSpring } from "@react-spring/web";
import Styles from "./Style";
import Dates from "../../components.js/Dates";
import ExtraSmall from "../../components.js/ExtraSmall";
import Condicions from "../../components.js/Condicions";
import ReservaIntro from "../../components.js/ReservaIntro";
import ResumReserva from "../../components.js/ResumReserva";
import { useTranslation } from "react-i18next";
import { datesBetween, datesBetween2, datesBetweenInclosos, mergeIntervals } from "../../utils/Utils";
import DialogSuccess from "../../components.js/DialogSuccess";
import { useSnackbar } from "notistack";
import parse from "html-react-parser";

const vacances = "cbbdq5e8mk85n9a5vi0d706lag@group.calendar.google.com";

const Home = () => {
    const classes = Styles();
    const { enqueueSnackbar } = useSnackbar();
    const [dateS, setDateS] = useState();
    const [dateF, setDateF] = useState();
    const [preu, setPreu] = useState(0);
    const [preuE, setPreuE] = useState(0);
    const [showNits, setShowNits] = useState(false);
    const [persones, setPersones] = useState("");
    const [personesRender, setPersonesRender] = useState();
    const [exclude, setExclude] = useState([]);
    const [showContinuar, setShowContinuar] = useState(false);
    const [loading, setLoading] = useState(true);
    const [habitacio, setHabitacio] = useState();
    const [habitacions, setHabitacions] = useState();
    const [preus, setPreus] = useState();
    const [params, setParams] = useState();
    const [extras, setExtras] = useState();
    const [selExtras, setSelExtras] = useState();
    const [langCalendar, setLangCalendar] = useState();
    const [nomContacte, setNomContacte] = useState();
    const [emailContacte, setEmailContacte] = useState();
    const [phoneContacte, setPhoneContacte] = useState();
    const [openSuccess, setOpenSuccess] = useState(false);
    const [success, setSuccess] = useState();
    const refDates2 = useRef();
    const [heightDates, setHeightDates] = useState(refDates2?.current?.clientHeight);
    const [intervals, setIntervals] = useState([]);
    const [dies, setDies] = useState([]);
    const [form, setForm] = useState("");
    const { t, i18n } = useTranslation();

    // Factura
    const [factura, setFactura] = useState({
        rao: "",
        nif: "",
        adreca: "",
        poblacio: "",
        codi_postal: "",
        pais: "",
    });

    useEffect(() => {
        setLoading(true);
        const obtenir = async () => {
            const habitacions = await get("habitacions");
            const preus = await get("preus");
            const params = await get("params");
            const extras = await get("extras");
            const dies = await get("dies");
            console.log(dies);

            setHabitacions(habitacions.resultat);
            setPreus(preus.resultat);
            setParams(params.resultat);
            setExtras(extras.resultat);
            setDies(dies.resultat);
            setLoading(false);
            setHeightDates(refDates2.current.clientHeight);
        };
        obtenir();
    }, []);

    useEffect(() => {
        switch (i18n.language) {
            case "ca":
                setLangCalendar(ca);
                break;

            case "es":
                setLangCalendar(es);
                break;

            case "en":
                setLangCalendar(enGB);
                break;

            default:
                break;
        }
    }, [i18n]);

    const props = useSpring({
        from: {
            opacity: 0,
            opacityD: 1,
            height: 0,
            heightD: heightDates,
        },
        opacity: showContinuar ? 1 : 0,
        opacityD: showContinuar ? 0 : 1,
        heightD: showContinuar ? 150 : heightDates + 40,
    });

    useEffect(() => {
        setHeightDates(refDates2.current.clientHeight);
    }, [selExtras]);

    useEffect(() => {
        let ex = extras?.map((item) => {
            return { ...item, checked: false, count: 1 };
        });
        setSelExtras(ex);
    }, [extras]);

    useEffect(() => {
        let pr = 0;
        selExtras?.forEach((item) => {
            if (item.checked) pr = pr + item.preu * item.count;
        });
        setPreuE(pr);
    }, [selExtras]);

    useEffect(() => {
        setShowNits(params?.filter((item) => item.sku === "nits-reserva")?.[0]?.value === "1");
    }, [params]);

    useEffect(() => {
        setLoading(true);
        setPersones("");

        const getCalendars = async () => {
            const result = await calendaris(habitacio?.target.value.calendari);

            const result_booking = await calendarisBooking(habitacio?.target.value.id);

            const result_vacances = await calendaris(vacances);

            const result_int = result.map((item) => {
                return { start: item?.start?.date, end: item?.end?.date };
            });

            const result_booking_int = result_booking.map((item) => {
                return {
                    start: moment(item.startDate, "YYYYMMDD").format("YYYY-MM-DD"),
                    end: moment(item.endDate, "YYYYMMDD").add(1, "days").format("YYYY-MM-DD"),
                };
            });

            const result_vacances_int = result_vacances.map((item) => {
                return { start: item?.start?.date, end: item?.end?.date };
            });

            const resultats = [...result_int, ...result_booking_int, ...result_vacances_int];

            if (resultats.length > 0) {
                const interval = resultats.map((i) => [moment(i.start).add(1, "day").format("YYYY-MM-DD"), i.end]);

                const result = mergeIntervals(interval);
                setIntervals(result);

                let ex = [];
                result.forEach((item) => {
                    if (item[0]) {
                        ex = [...ex, ...datesBetween2(item[0], item[1])];
                    }
                });

                setExclude(ex);
            }

            var rows = [];
            for (var i = habitacio?.target.value.min_persones - 1; i < habitacio?.target.value.persones; i++) {
                if (!(habitacio.target.value.discapacitats && i === 0)) {
                    rows.push(
                        <MenuItem value={i}>
                            {i + 1} {i === 0 ? t("persona") : t("persones")}
                        </MenuItem>
                    );
                }
            }
            setPersonesRender(rows);
            setTimeout(() => {
                setLoading(false);
            }, 300);
        };

        habitacio && getCalendars();
    }, [habitacio]);

    useEffect(() => {
        const datesOcupades = datesBetween(dateS, dateF);

        const preu = preus?.filter((i) => i?.persones === persones + 1 && Number(i?.habitacio_id) === habitacio?.target?.value.id)?.[0]?.preu;

        setPreu(datesOcupades.length === 0 ? preu : datesOcupades.length * preu);
    }, [persones]);

    const onChange = (dates) => {
        const [start, end] = dates;
        const datesOcupades = datesBetweenInclosos(start, end);
        const diaTrobat = dies?.find((dia) => moment(dia.dia).format("YYYY-DD-MM") === moment(start).format("YYYY-DD-MM"));

        console.log(diaTrobat);

        let trobat = [];
        let trobat_bool = false;
        let data1;
        let data2;
        intervals.forEach((data) => {
            const datesInterval = datesBetweenInclosos(
                moment(data[0]).subtract(1, "day").toDate().getTime(),
                moment(data[1]).subtract(1, "day").toDate().getTime()
            );
            let i = 0;
            datesInterval.forEach((dataInt, index) => {
                datesOcupades.forEach((item, index) => {
                    if (item.getTime() === dataInt.getTime()) {
                        i = i + 1;
                    }
                    if (i === 2) {
                        trobat_bool = true;
                        data1 = moment(data[0]).subtract(1, "day");
                        data2 = moment(data[1]).subtract(1, "day");
                    }
                });
            });
        });
        if (trobat_bool) {
            enqueueSnackbar(t("Hi ha una reserva entre les dates seleccionades: " + data1.format("YYYY-MM-DD") + " i " + data2.format("YYYY-MM-DD")), {
                variant: "warning",
            });
            trobat = [...trobat, data1];
        }
        datesOcupades.forEach((item) => {
            trobat = [...trobat, ...exclude.filter((d) => d.getTime() === item.getTime())];
        });

        const datesOcupadesNoInclude = datesBetween(start, end);

        // const boolDosNits = showNits ? datesOcupadesNoInclude.length === 0 || datesOcupadesNoInclude.length >= 2 : true;
        const boolDosNits = Number(diaTrobat?.dosnits) ? datesOcupadesNoInclude.length === 0 || datesOcupadesNoInclude.length >= 2 : true;

        let preu = preus?.filter((i) => i?.persones === persones + 1 && Number(i?.habitacio_id) === habitacio?.target?.value.id)?.[0]?.preu;

        if (diaTrobat) {
            preu = Math.round(preu * (diaTrobat.tantpercent_queralt ? diaTrobat.tantpercent_queralt / 100 + 1 : 1) * 100) / 100;
        }

        if (trobat.length === 0 && start?.getTime() !== end?.getTime() && boolDosNits && moment(start).day() !== 1) {
            setDateS(start);
            setDateF(end);
            setPreu(datesOcupadesNoInclude.length === 0 ? preu : Math.round(datesOcupadesNoInclude.length * preu * 100) / 100);
        } else {
            setDateS();
            setDateF();
            setPreu(preu);
            if (Number(diaTrobat?.dosnits) && moment(start).day() !== 1) {
                enqueueSnackbar(t("A aquestes dates, són mínim dos nits"), {
                    variant: "warning",
                });
            }
        }
    };

    const changeHabitacio = (value) => {
        setHabitacio(value);
        setDateS();
        setDateF();
    };

    const continuar = () => {
        setShowContinuar(!showContinuar);
    };

    const continuarCheckIn = async () => {
        setLoading(true);
        const values = {
            persones_val: persones + 1,
            dateS: moment(dateS).format("YYYY-MM-DD"),
            dateF: moment(dateF).format("YYYY-MM-DD"),
            habitacio_id: habitacio?.target?.value.id,
            nom_contacte: nomContacte,
            email_contacte: emailContacte,
            phone_contacte: phoneContacte,
            extra: JSON.stringify(selExtras),
            preu: preu + preuE,
            state_id: 6,
            rao: factura?.rao,
            nif: factura?.nif,
            adreca: factura?.adreca,
            poblacio: factura?.poblacio,
            codi_postal: factura?.codi_postal,
            pais: factura?.pais,
            booking: "0",
        };

        const { state } = await ferReserva(values);
        if (state === 200) {
            setDateS();
            setDateF();
            setPreu(0);
            setPreuE(0);
            setPersones("");
            setPersonesRender();
            setExclude([]);
            setPreus();
            setSelExtras();
            setNomContacte();
            setEmailContacte();
            setPhoneContacte();
            setFactura({
                rao: "",
                nif: "",
                adreca: "",
                poblacio: "",
                codi_postal: "",
                pais: "",
            });
        }

        // const { form, message, state } = await createReservaSenseCheckin(values);

        // console.log(form);
        // setForm(form);

        // if (state === 200) {
        //     setTimeout(() => document.forms["redsys_form"].submit(), 500);
        // }
        // // if (state === 200) {
        // //     setDateS();
        // //     setDateF();
        // //     setPreu(0);
        // //     setPreuE(0);
        // //     setPersones("");
        // //     setPersonesRender();
        // //     setExclude([]);
        // //     setPreus();
        // //     setNomContacte("");
        // //     setEmailContacte("");
        // //     setPhoneContacte("");
        // //     setFactura({
        // //         rao: "",
        // //         nif: "",
        // //         adreca: "",
        // //         poblacio: "",
        // //         codi_postal: "",
        // //         pais: "",
        // //     });
        // // }
        // console.log("finalitza");

        setLoading(false);
        setSuccess(state);
        setOpenSuccess(true);
    };

    return (
        <Box className={classes.main}>
            <Fade in={loading}>
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
            </Fade>

            <ReservaIntro />

            <Container>
                <LocalizationProvider locale={ca} utils={DateFnsUtils} dateAdapter={DateFnsUtils}>
                    <animated.div
                        className={clsx(classes.reserva, classes.first)}
                        style={{
                            height: props.heightD,
                        }}
                    >
                        <ResumReserva props={props} habitacio={habitacio} dateF={dateF} dateS={dateS} preu={preu} preuE={preuE} />
                        <animated.div
                            ref={refDates2}
                            style={{
                                opacity: props.opacityD,
                            }}
                        >
                            <Box mb={3}>
                                <Typography variant="h3">{t("Fes la reserva")}</Typography>
                            </Box>
                            {parse(form ? form.data : "")}

                            <Grid container spacing={3}>
                                <Grid item md={8}>
                                    <Box className={classes.form}>
                                        <Box>
                                            <Box mb={3}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">{t("Habitació")}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={habitacio}
                                                        label="Habitació"
                                                        onChange={(value) => changeHabitacio(value)}
                                                        MenuProps={{
                                                            disableScrollLock: true,
                                                        }}
                                                    >
                                                        {habitacions?.map((hab) => (
                                                            <MenuItem value={hab}>
                                                                {i18n.language === "en" ? hab.nom_en : i18n.language === "es" ? hab.nom_es : hab.nom}
                                                                {hab.discapacitats ? (
                                                                    <WheelchairPickupRounded
                                                                        style={{
                                                                            marginLeft: 10,
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>

                                            <Box mb={3}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">{t("Persones")}</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={persones}
                                                        label="Persones"
                                                        onChange={(value) => {
                                                            setPersones(value.target.value);
                                                        }}
                                                        MenuProps={{
                                                            disableScrollLock: true,
                                                        }}
                                                    >
                                                        {personesRender}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box mb={3} mt={3}>
                                                <Grid spacing={3} container>
                                                    <Grid item md={4} xs={12}>
                                                        <TextField
                                                            name="nom_contacte"
                                                            label={t("Nom i cognoms")}
                                                            type="text"
                                                            value={nomContacte}
                                                            onChange={(value) => setNomContacte(value.target.value)}
                                                            fullWidth
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item md={4} xs={12}>
                                                        <TextField
                                                            name="nom_contacte"
                                                            label="E-mail"
                                                            type="text"
                                                            value={emailContacte}
                                                            onChange={(value) => setEmailContacte(value.target.value)}
                                                            fullWidth
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item md={4} xs={12}>
                                                        <TextField
                                                            name="phone_contacte"
                                                            label={t("Telèfon")}
                                                            type="text"
                                                            value={phoneContacte}
                                                            onChange={(value) => setPhoneContacte(value.target.value)}
                                                            fullWidth
                                                            required
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Dates dateF={dateF} dateS={dateS} />
                                            <Typography variant="caption">{t("Selecciona la data al calendari situat a la dreta")}</Typography>
                                            <br />
                                            {showNits && <Typography variant="caption">{t("Mínim dues nits")}</Typography>}
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item md={4} textAlign="center">
                                    <ReactDatePicker
                                        onChange={onChange}
                                        startDate={dateS}
                                        endDate={dateF}
                                        excludeDates={exclude}
                                        // filterDate={disabledDate}
                                        selectsRange
                                        selectsDisabledDaysInRange
                                        locale={langCalendar}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        inline
                                    />
                                    <Typography variant="caption" textAlign={"center"}>
                                        {t("L'estança no pot començar un dilluns")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box my={2}>
                                <Typography variant="h3">{t("Extres")}</Typography>
                            </Box>
                            <Grid container spacing={3}>
                                {selExtras?.map((item) => (
                                    <ExtraSmall item={item} setSelExtras={setSelExtras} />
                                ))}
                            </Grid>
                            <Box my={4} />
                            <Box display="flex" justifyContent="flex-start" alignItems="flex-end">
                                <Box mr={2}>
                                    <Typography variant="h3">{t("Preu")}:</Typography>
                                </Box>

                                <Typography variant="h1" fontWeight={600}>
                                    <strong>{preu ? preu + preuE : 0} €</strong>
                                </Typography>
                                <Box ml={2}>
                                    <Typography variant="caption">{t("IVA inclòs")}</Typography>
                                </Box>
                            </Box>
                        </animated.div>
                    </animated.div>

                    <Box className={classes.continuar}>
                        <CustomButton
                            onClick={continuar}
                            title={showContinuar ? t("Modificar data") : t("Continuar amb check-in i pagament")}
                            disabled={!dateS || !nomContacte || !emailContacte || !phoneContacte || !dateF || !habitacio || persones === ""}
                        />
                        {/* <Box mx={2} />
                        <CustomButton
                            onClick={continuarCheckIn}
                            title={"Fer reserva sense check-in"}
                            disabled={!dateS || !nomContacte || !emailContacte || !phoneContacte || !dateF || !habitacio || persones === ""}
                        /> */}
                    </Box>
                    {/* <FormFactura factura={factura} setFactura={setFactura} /> */}
                </LocalizationProvider>
                <FormulariReserva
                    showContinuar={showContinuar}
                    habitacio={habitacio?.target?.value}
                    dateS={dateS}
                    dateF={dateF}
                    persones={persones}
                    preu={preu + preuE}
                    loading={loading}
                    setLoading={setLoading}
                    selExtras={selExtras}
                    nomContacte={nomContacte}
                    emailContacte={emailContacte}
                    phoneContacte={phoneContacte}
                    factura={factura}
                />
                <Condicions showNits={showNits} />
            </Container>
            <DialogSuccess open={openSuccess} setOpen={setOpenSuccess} success={success} />
        </Box>
    );
};

export default Home;
