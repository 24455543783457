import React, { createRef, useEffect, useRef, useState } from "react";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputLabel,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import CustomButton from "./CustomButton";
import { animated, useSpring } from "@react-spring/web";
import Styles from "../views/public/Style";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Add, QuestionMark } from "@mui/icons-material";
import moment from "moment";
import { addElements, checkin, createReserva, createReservaAdmin, createReservaXec, getElements } from "../database/API";
import parse from "html-react-parser";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { SchemaRes } from "./SchemaReserva";
import { useTranslation } from "react-i18next";
import Thumb from "./Thumb";
import { DialogExplicacio } from "./DialogExplicacio";
import { relacio } from "../utils/Utils";

const FormulariReserva = ({
    showContinuar,
    habitacio,
    dateF,
    dateS,
    persones,
    preu,
    setLoading,
    adminView,
    selExtras,
    xecView,
    codi,
    factura,
    nomContacte,
    emailContacte,
    phoneContacte,
    checkinForm,
    unique_id,
}) => {
    const classes = Styles();
    const refForm = useRef();
    const [paisos, setPaisos] = useState([]);
    const [heightForm, setHeightForm] = useState(refForm?.current?.clientHeight);
    const navigate = useNavigate();
    const [form, setForm] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const [values_persones, setValuesPersones] = useState([]);
    const [openExplicacio, setOpenExplicacio] = useState(false);
    const [provincies, setProvincies] = useState([]);
    const [municipis, setMunicipis] = useState([]);
    const { schema } = SchemaRes();
    const personesRefs = useRef([]);
    const { t, i18n } = useTranslation();

    const {
        register,
        unregister,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        trigger,
        watch,
        control,
        clearErrors,
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const subscription = watch(() => {
            setTimeout(() => {
                setHeightForm(refForm?.current?.clientHeight);
                personesRefs.current = values_persones.map((_, i) => personesRefs.current[i] ?? createRef());
            }, 200);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        setValue("habitacio", habitacio?.id);
        setValue("preu", preu);
        setValue("dateS", moment(dateS).format("YYYY-MM-DD"));
        setValue("dateF", moment(dateF).format("YYYY-MM-DD"));
        setValue("extra", JSON.stringify(selExtras));
        setValue("lang", i18n.language);
        setValue("nom_contacte", nomContacte);
        setValue("email_contacte", emailContacte);
        setValue("phone_contacte", phoneContacte);
        setValue("factura", factura);
        if (xecView) {
            setValue("codi", codi);
        }
    }, [preu, habitacio, dateS, dateF, selExtras, i18n.language, nomContacte, emailContacte, phoneContacte, factura]);

    useEffect(() => {
        const obtenir = async () => {
            const { data } = await getElements("dadesDireccio");
            setProvincies(data.provincies);
            setMunicipis(data.municipis);
            setPaisos(data.paisos);

            // setPaisos(paisosNoms.sort((a, b) => (a.nom > b.nom ? 1 : -1)));
        };
        obtenir();
    }, []);

    useEffect(() => {
        unregister("persones");
        clearErrors();
        let vals = [];
        for (var i = 0; i < persones + 1; i++) {
            vals.push({
                nom: "",
                cognom1: "",
                cognom2: "",
                accepta: false,
                genere: "M",
                nacionalitat: "",
                naixement: "",
                tipus: "D",
                dni: "",
                caducitat: "",
                suport: "",
                dniDarrere: "",
                dniDavant: "",
                menor: false,
                municipi: "",
                codi_postal: "",
                relacio: "",
            });
        }
        setValuesPersones(vals);
        const timer = setTimeout(() => {
            setHeightForm(refForm?.current?.clientHeight);
            personesRefs.current = values_persones.map((_, i) => personesRefs.current[i] ?? createRef());
        }, 500);
        return () => clearTimeout(timer);
    }, [persones]);

    const props = useSpring({
        from: {
            opacity: 0,
            height: 0,
        },
        opacity: showContinuar ? 1 : 0,
        height: showContinuar ? heightForm : 0,
    });

    const enviar = async (values) => {
        console.log(values);
        setLoading(true);
        if (adminView) {
            const { message } = await createReservaAdmin(values);
            enqueueSnackbar(message, {
                variant: "success",
            });
            navigate("/admin");
        } else {
            if (xecView) {
                const { message, reserva_id } = await createReservaXec(values);
                enqueueSnackbar(message, {
                    variant: "success",
                });
                navigate("/exit/" + reserva_id);
            } else {
                if (checkinForm) {
                    let pers = getValues("persones");
                    let majors = pers.filter((i) => i.menor === false);
                    if (majors.length >= 1) {
                        const { form } = await checkin(values, unique_id);
                        setForm(form);
                        setTimeout(() => document.forms["redsys_form"].submit(), 500);
                    } else {
                        enqueueSnackbar(t("Al menys hi ha d'haver un major"), {
                            variant: "error",
                        });
                    }
                } else {
                    let pers = getValues("persones");
                    let majors = pers.filter((i) => i.menor === false);
                    if (majors.length >= 1) {
                        const { form } = await createReserva(values);
                        setForm(form);
                        setTimeout(() => document.forms["redsys_form"].submit(), 500);
                    } else {
                        enqueueSnackbar(t("Al menys hi ha d'haver un major"), {
                            variant: "error",
                        });
                    }
                }
            }
        }
    };

    return (
        <Box>
            <form onSubmit={handleSubmit(enviar)}>
                <animated.div
                    className={classes.reserva}
                    style={{
                        opacity: props.opacity,
                        height: props.height,
                    }}
                >
                    {parse(form ? form.data : "")}
                    <Box ref={refForm}>
                        <Box mb={2}>
                            <Typography variant="h2">{t("Omple les dades")}</Typography>
                        </Box>
                        <input {...register("habitacio")} type="text" hidden />
                        <input {...register("dateS")} type="text" hidden />
                        <input {...register("dateF")} type="text" hidden />
                        <input {...register("preu")} hidden />
                        <input {...register("extra")} hidden />
                        <input {...register("lang")} hidden />
                        <input {...register("nom_contacte")} hidden />
                        <input {...register("email_contacte")} hidden />
                        <input {...register("phone_contacte")} hidden />
                        {xecView && <input {...register("codi")} hidden />}
                        {values_persones.map((value, index) => {
                            const fieldName = `persones[${index}]`;
                            return (
                                <>
                                    <Box my={6}>
                                        <Typography variant="h3">
                                            {t("Persona")} {index + 1}
                                            {index === 0 ? " (" + t("Contacte") + ")" : ""}
                                        </Typography>
                                    </Box>

                                    <Grid container spacing={3}>
                                        <Grid item md={3} xs={12}>
                                            <TextField
                                                error={Boolean(errors && errors?.persones?.[index]?.nom)}
                                                helperText={errors && errors?.persones?.[index]?.nom?.message}
                                                key={index}
                                                fullWidth
                                                label={t("Nom")}
                                                name={`${fieldName}.nom`}
                                                type="text"
                                                variant="outlined"
                                                {...register(`persones.${index}.nom`)}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <TextField
                                                error={Boolean(errors && errors?.persones?.[index]?.cognom1)}
                                                helperText={errors && errors?.persones?.[index]?.cognom1?.message}
                                                fullWidth
                                                label={t("Primer cognom")}
                                                type="text"
                                                variant="outlined"
                                                name={`${fieldName}.cognom1`}
                                                {...register(`persones.${index}.cognom1`)}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <TextField
                                                error={Boolean(errors && errors?.persones?.[index]?.cognom2)}
                                                helperText={errors && errors?.persones?.[index]?.cognom2?.message}
                                                fullWidth
                                                label={t("Segon cognom")}
                                                type="text"
                                                variant="outlined"
                                                name={`${fieldName}.cognom2`}
                                                {...register(`persones.${index}.cognom2`)}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <TextField
                                                error={Boolean(errors && errors?.persones?.[index]?.naixement)}
                                                helperText={errors && errors?.persones?.[index]?.naixement?.message}
                                                fullWidth
                                                label={t("Data naixement")}
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name={`${fieldName}.naixement`}
                                                {...register(`persones.${index}.naixement`)}
                                                onChange={(val) => {
                                                    var ageDifMs = Date.now() - Date.parse(val.target.value);
                                                    var ageDate = new Date(ageDifMs); // miliseconds from epoch
                                                    var years = Math.abs(ageDate.getUTCFullYear() - 1970);
                                                    if (years <= 14) {
                                                        setValue(`persones.${index}.menor`, true);
                                                    } else {
                                                        setValue(`persones.${index}.menor`, false);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    {watch(`persones.${index}.menor`)}
                                    {index === 0 ? (
                                        <input type="checkbox" {...register(`persones.${index}.menor`)} value={false} hidden />
                                    ) : (
                                        <Box>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name={`persones.${index}.menor`}
                                                        control={control}
                                                        disabled
                                                        defaultValue={!!watch(`persones.${index}.menor`)}
                                                        render={({ field }) => <Checkbox disabled checked={field.value} onChange={field.onChange} />}
                                                    />
                                                }
                                                style={{
                                                    paddingTop: 20,
                                                }}
                                                label={
                                                    <Box lineHeight={"9px"}>
                                                        <Typography>{t("Menor de 14 anys")}</Typography>
                                                    </Box>
                                                }
                                            />
                                            <Box mt={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="relacio">{t("Parentesc amb la persona 1 (Contacte)")}</InputLabel>
                                                    <Select
                                                        native
                                                        label={t("Parentesc amb la persona 1 (de contacte)")}
                                                        name={`${fieldName}.relacio`}
                                                        {...register(`persones.${index}.relacio`)}
                                                    >
                                                        <option value={""}></option>
                                                        {relacio?.map((rel) => (
                                                            <option value={rel.id}>{rel.nom}</option>
                                                        ))}
                                                    </Select>
                                                    <Typography variant="error">{errors?.persones?.[index]?.relacio?.message}</Typography>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    )}

                                    {!watch(`persones.${index}.menor`) && (
                                        <Box ref={personesRefs.current[index]}>
                                            <Box my={4} />
                                            <Box mt={3} mb={2}>
                                                <Typography variant="h3">{t("Dades del document d'identitat")}</Typography>
                                            </Box>
                                            <Grid container spacing={3}>
                                                <Grid item md={3} xs={12}>
                                                    <Box mt={2}>
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="tipus">{t("Tipus de document")}</InputLabel>
                                                            <Select
                                                                native
                                                                label={t("Tipus de document")}
                                                                name={`${fieldName}.tipus`}
                                                                {...register(`persones.${index}.tipus`)}
                                                            >
                                                                <option value={"D"}>DNI</option>
                                                                <option value={"P"}>{t("Passaport")}</option>
                                                                <option value={"C"}>{t("Carnet de conduir")}</option>
                                                                <option value={"I"}>{t("Carta d'identitat")}</option>
                                                                <option value={"N"}>{t("Permís de residència")}</option>
                                                                <option value={"X"}>{t("Permís de residència fora UE")}</option>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <TextField
                                                        error={Boolean(errors && errors?.persones?.[index]?.dni)}
                                                        helperText={errors && errors?.persones?.[index]?.dni?.message}
                                                        fullWidth
                                                        label={t("Número de document")}
                                                        margin="normal"
                                                        variant="outlined"
                                                        name={`${fieldName}.dni`}
                                                        {...register(`persones.${index}.dni`)}
                                                    />
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <TextField
                                                        error={Boolean(errors && errors?.persones?.[index]?.caducitat)}
                                                        helperText={errors && errors?.persones?.[index]?.caducitat?.message}
                                                        fullWidth
                                                        label={t("Data d'expedició")}
                                                        margin="normal"
                                                        type="date"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            inputProps: {
                                                                max: moment().format("YYYY-MM-DD"),
                                                            },
                                                        }}
                                                        name={`${fieldName}.caducitat`}
                                                        {...register(`persones.${index}.caducitat`)}
                                                    />
                                                </Grid>
                                                <Grid item md={3} xs={12}>
                                                    <Box mt={2}>
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="genere">{t("Gènere")}</InputLabel>
                                                            <Select
                                                                native
                                                                label={t("Gènere")}
                                                                name={`${fieldName}.genere`}
                                                                {...register(`persones.${index}.genere`)}
                                                            >
                                                                <option value={"M"}>M</option>
                                                                <option value={"F"}>F</option>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box my={2} />
                                            <Grid container spacing={3}>
                                                <Grid item md={6} mt={2} xs={12}>
                                                    <FormControl fullWidth>
                                                        <InputLabel htmlFor="tipus">{t("País")}</InputLabel>
                                                        <Select
                                                            native
                                                            label={t("País")}
                                                            name={`${fieldName}.tipus`}
                                                            {...register(`persones.${index}.nacionalitat`)}
                                                        >
                                                            <option value={""}></option>
                                                            {paisos.map((pais) => (
                                                                <option value={pais?.id}>{pais?.nom}</option>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {/* <FormControl fullWidth>
                                                        <Autocomplete
                                                            disablePortal
                                                            id={`persones.${index}.nacionalitat`}
                                                            options={paisos}
                                                            renderInput={(params) => (
                                                                <TextField {...params} {...register(`persones.${index}.nacionalitat`)} label={t("País")} />
                                                            )}
                                                        />
                                                    </FormControl> */}
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <Box display={"flex"} alignItems={"center"}>
                                                        <TextField
                                                            error={Boolean(errors && errors?.persones?.[index]?.suport)}
                                                            helperText={errors && errors?.persones?.[index]?.suport?.message}
                                                            fullWidth
                                                            label={t("Número suport document identificatiu")}
                                                            margin="normal"
                                                            type="text"
                                                            InputProps={{ maxLength: 9, minLength: 9 }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            name={`${fieldName}.suport`}
                                                            {...register(`persones.${index}.suport`)}
                                                        />
                                                        <Box ml={2}>
                                                            <IconButton onClick={() => setOpenExplicacio(true)}>
                                                                <QuestionMark />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item md={6} mt={2} xs={12}>
                                                    <Collapse in={watch(`persones.${index}.nacionalitat`) === "ESP"}>
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="tipus">{t("Provincia")}</InputLabel>
                                                            <Select
                                                                native
                                                                label={t("Provincia")}
                                                                name={`${fieldName}.tipus`}
                                                                {...register(`persones.${index}.provincia`)}
                                                            >
                                                                <option value={""}></option>
                                                                {provincies.map((provincia) => (
                                                                    <option value={provincia?.id}>{provincia?.nom}</option>
                                                                ))}
                                                            </Select>
                                                            <Typography variant="error">{errors?.persones?.[index]?.provincia?.message}</Typography>
                                                        </FormControl>
                                                    </Collapse>
                                                </Grid>

                                                <Grid item md={6} mt={2} xs={12}>
                                                    <Collapse in={watch(`persones.${index}.nacionalitat`) === "ESP"}>
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="tipus">{t("Municipi")}</InputLabel>
                                                            <Select
                                                                native
                                                                label={t("Municipi")}
                                                                name={`${fieldName}.tipus`}
                                                                {...register(`persones.${index}.municipi`)}
                                                            >
                                                                <option value={""}></option>
                                                                {municipis
                                                                    .filter((m) => m.provincia_id === watch(`persones.${index}.provincia`))
                                                                    .map((municipi) => (
                                                                        <option value={municipi?.id}>{municipi?.nom}</option>
                                                                    ))}
                                                            </Select>
                                                            <Typography variant="error">{errors?.persones?.[index]?.municipi?.message}</Typography>
                                                        </FormControl>
                                                    </Collapse>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item md={4} xs={12}>
                                                    <TextField
                                                        error={Boolean(errors && errors?.persones?.[index]?.codi_postal)}
                                                        helperText={errors && errors?.persones?.[index]?.codi_postal?.message}
                                                        fullWidth
                                                        label={t("Còdi postal")}
                                                        margin="normal"
                                                        variant="outlined"
                                                        name={`${fieldName}.codi_postal`}
                                                        {...register(`persones.${index}.codi_postal`)}
                                                    />
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <TextField
                                                        error={Boolean(errors && errors?.persones?.[index]?.poblacio)}
                                                        helperText={errors && errors?.persones?.[index]?.poblacio?.message}
                                                        fullWidth
                                                        label={t("Població/Localitat")}
                                                        margin="normal"
                                                        variant="outlined"
                                                        name={`${fieldName}.poblacio`}
                                                        {...register(`persones.${index}.poblacio`)}
                                                    />
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <TextField
                                                        error={Boolean(errors && errors?.persones?.[index]?.direccio)}
                                                        helperText={errors && errors?.persones?.[index]?.direccio?.message}
                                                        fullWidth
                                                        label={t("Adreça")}
                                                        margin="normal"
                                                        variant="outlined"
                                                        name={`${fieldName}.direccio`}
                                                        {...register(`persones.${index}.direccio`)}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={3}>
                                                <Grid item md={6} xs={12}>
                                                    <Box mt={3}>
                                                        <Box display="flex" justifyContent="space-between">
                                                            <Box>
                                                                <Typography variant={"body1"}>{t("Fotografia document (davant)")}</Typography>
                                                                <label htmlFor={`dniDavant${index}`}>
                                                                    <input
                                                                        id={`dniDavant${index}`}
                                                                        name={`${fieldName}.dniDavant`}
                                                                        type="file"
                                                                        {...register(`persones.${index}.dniDavant`, {
                                                                            onChange: () => {
                                                                                trigger(`persones.${index}.dniDavant`);
                                                                            },
                                                                        })}
                                                                        style={{
                                                                            display: "none",
                                                                        }}
                                                                    />
                                                                    <Box className={classes.boto}>
                                                                        <Add />
                                                                        <Typography
                                                                            style={{
                                                                                color: "white",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {t("Afegir imatge")}
                                                                        </Typography>
                                                                    </Box>
                                                                </label>
                                                            </Box>
                                                            <Box p={2}>
                                                                <Thumb file={getValues(`persones.${index}.dniDavant`)?.[0]} />
                                                            </Box>
                                                        </Box>
                                                        <Typography variant="error">{errors?.persones?.[index]?.dniDavant?.message}</Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item md={6} xs={12}>
                                                    <Box mt={3}>
                                                        <Box display="flex" justifyContent="space-between">
                                                            <Box>
                                                                <Typography variant={"body1"}>{t("Fotografia document (darrere)")}</Typography>
                                                                <label htmlFor={`dniDarrere${index}`}>
                                                                    <input
                                                                        id={`dniDarrere${index}`}
                                                                        name={`${fieldName}.dniDarrere`}
                                                                        type="file"
                                                                        {...register(`persones.${index}.dniDarrere`, {
                                                                            onChange: () => {
                                                                                trigger(`persones.${index}.dniDarrere`);
                                                                            },
                                                                        })}
                                                                        style={{
                                                                            display: "none",
                                                                        }}
                                                                    />
                                                                    <Box className={classes.boto}>
                                                                        <Add />
                                                                        <Typography
                                                                            style={{
                                                                                color: "white",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {t("Afegir imatge")}
                                                                        </Typography>
                                                                    </Box>
                                                                </label>
                                                            </Box>
                                                            <Box p={2}>
                                                                <Thumb file={getValues(`persones.${index}.dniDarrere`)?.[0]} />
                                                            </Box>
                                                        </Box>

                                                        <Typography variant="error">{errors?.persones?.[index]?.dniDarrere?.message}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}

                                    <Box my={4}>
                                        <Divider />
                                    </Box>
                                </>
                            );
                        })}
                        <Box py={2} />
                        {!adminView && (
                            <>
                                <FormControlLabel
                                    control={<Checkbox onChange={(e) => {}} name="accepta" {...register("accepta")} required />}
                                    label={
                                        <Typography>
                                            {t("He llegit, entenc i accepto la")}{" "}
                                            <a
                                                href="https://lacabanaberga.cat/politica-de-privacitat"
                                                target="_blank"
                                                alt={t("Política de Privacitat")}
                                                rel="noreferrer"
                                            >
                                                {t("Política de Privacitat")}
                                            </a>
                                        </Typography>
                                    }
                                />
                                <FormControlLabel
                                    control={<Checkbox onChange={(e) => {}} name="news" {...register("news")} />}
                                    label={
                                        <Typography>
                                            {t(
                                                "Dono el meu consentiment exprés per rebre comunicacions dels serveis sol·licitats de LA CABANA BERGA, S.L. per correu electrònic, WhatsApp i altres mitjans de comunicació digital, segons l'establert a la Llei LSSI/CE 34/2002."
                                            )}
                                        </Typography>
                                    }
                                />
                            </>
                        )}
                    </Box>
                </animated.div>
                <animated.div
                    className={classes.continuar}
                    style={{
                        opacity: props.opacity,
                    }}
                >
                    <CustomButton type="submit" title={t("Crear reserva")} />
                </animated.div>
            </form>
            <DialogExplicacio setOpen={setOpenExplicacio} open={openExplicacio} />
        </Box>
    );
};

export default FormulariReserva;
